import IMAGE1 from '../images/AranCucine_Prodotti_Modulo-13_Lab13_1080x1080_04.jpg'
import IMAGE2 from '../images/AranCucine_Prodotti_Modulo-13_Lab13_1080x1080_07.jpg'

export const post = {
  id: 'aran',
  title: 'Aran Cucine',
  paragraphs: [
    'We are a proud representative for Aran Cucine kitchens.',
  ],
  images: [
    IMAGE1,
    IMAGE2,
  ],
  products: [],
  videos: [],
}


