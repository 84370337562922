import { post as christoffPost } from '../tmp/posts/Christoff1';
import { post as elibankPost } from '../tmp/posts/Elibank';
import { post as utilityRoomPost } from '../tmp/posts/Utility';
import { post as lacancheAppliance } from '../tmp/appliances/lacanche/Lacanche';
import { post as quookerAppliance } from '../tmp/appliances/quooker/Quooker';
import { post as aranPost } from '../tmp/posts/Aran';
import { post as aranMascaPost } from '../tmp/posts/AranMasca';
import { post as aranVolarePost } from '../tmp/posts/AranVolare';

const KEY_FIRST_POST_DISPLAYED = 'dkk-fpd1';
const KEY_NUM_POSTS_DISPLAYED = 'dkk-npd1';
const KEY_FIRST_APPLIANCE_DISPLAYED = 'dkk-fad1';
const KEY_NUM_APPLIANCES_DISPLAYED = 'dkk-nad1';

function convertLocalStorageNumber(value) {
    const returning = parseInt(value, 10);
    if (returning) {
        return returning;
    } else {
        return 0;
    }
}

export function persistFirstPostIndexDisplayed(index) {
    window.localStorage.setItem(KEY_FIRST_POST_DISPLAYED, '' + index);
}

export function getPersistedFirstPostIndexDisplayed() {
    return convertLocalStorageNumber(window.localStorage.getItem(KEY_FIRST_POST_DISPLAYED));
}

export function persistNumberOfPostsDisplayed(number) {
    window.localStorage.setItem(KEY_NUM_POSTS_DISPLAYED, '' + number);
}

export function getPersistedNumberOfPostsDisplayed() {
    return convertLocalStorageNumber(window.localStorage.getItem(KEY_NUM_POSTS_DISPLAYED));
}

export function persistFirstApplianceIndexDisplayed(index) {
    window.localStorage.setItem(KEY_FIRST_APPLIANCE_DISPLAYED, '' + index);
}

export function getPersistedFirstApplianceIndexDisplayed() {
    return convertLocalStorageNumber(window.localStorage.getItem(KEY_FIRST_APPLIANCE_DISPLAYED));
}

export function persistNumberOfAppliancesDisplayed(number) {
    window.localStorage.setItem(KEY_NUM_APPLIANCES_DISPLAYED, '' + number)
}

export function getPersistedNumberOfAppliancesDisplayed() {
    return convertLocalStorageNumber(window.localStorage.getItem(KEY_NUM_APPLIANCES_DISPLAYED));
}

export function getApplianceIds() {
    return [
        'lacanche',
        'quooker',
    ]
}

export function getPostIds() {
    return [
        'aran-volare',
        'aran-masca',
        'christoff',
        'elibank',
        'utility',
        'aran',
    ];
}

export function getPostOrAppliance(id) {
    switch (id) {
        case 'christoff':
            return christoffPost;
        case 'elibank':
            return elibankPost;
        case 'utility':
            return utilityRoomPost;
        case 'aran':
            return aranPost;
        case 'aran-masca':
            return aranMascaPost;
        case 'aran-volare':
            return aranVolarePost;
        case 'lacanche':
            return lacancheAppliance;
        case 'quooker':
            return quookerAppliance;
        default:
            return {};
    }
}