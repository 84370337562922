import IMAGE1 from '../images/tmp-post-dkk-2-1.jpg'
import IMAGE2 from '../images/tmp-post-dkk-2-2.jpg'
import IMAGE3 from '../images/tmp-post-dkk-2-3.jpg'

export const post = {
  id: 'utility',
  title: 'David Kiely Classic Bespoke Utility room',
  paragraphs: [
    'Another fabulous bespoke Utility room design by David Kiely Kitchens. This Utility room is very practical and provides extra space & storage in your home for bigger appliances which helps to keep your kitchen clutter and noise free!',
    'This Utility room is a stepped shaker style and is painted in the colour skimming stone. The handles chosen here are antique brass  which work so well with this colour.'
  ],
  images: [
    IMAGE1,
    IMAGE2,
    IMAGE3,
  ],
  products: [],
  videos: [],
}


