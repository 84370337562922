import { useParams } from "react-router-dom";
import { Squeeze, Video } from "../components/containers";
import DKLogoHeader from "../components/DKLogoHeader";
import PageWithMenu from "../components/PageWithMenu";
import { getPostOrAppliance } from "../utilities/storage";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useWindow } from "../utilities/document";
import { useLayoutEffect, useRef, useState } from "react";

const Post = () => {

  const params = useParams();
  const content = getPostOrAppliance(params.id);

  const IdeallySizedImage = ({ image, index }) => {

    const imageRef = useRef();
    // less the height of the scroll-down menu
    const windowHeight = useWindow().height - 44;
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [style, setStyle] = useState({});

    function dimensionsFromLoadedImage() {
      if (imageRef.current) {
        setDimensions({
          width: imageRef.current.clientWidth,
          height: imageRef.current.clientHeight
        });
      }
    }

    useLayoutEffect(() => {
      const aspectRatio = dimensions.width / dimensions.height;
      if (dimensions.height > windowHeight) {
        setStyle({
          height: windowHeight + 'px',
          width: windowHeight * aspectRatio + 'px',
        })
      }
    }, [dimensions, index, windowHeight]);

    useLayoutEffect(() => {
    }, [index, style]);

    return (
      <img
        ref={imageRef}
        src={image}
        alt=''
        style={{
          width: '100%',
          height: 'auto',
          paddingBottom: '5px',
          ...style,
        }}
        onLoad={dimensionsFromLoadedImage}
      />
    );
  }

  const Gallery = ({ omitFirst }) => {
    return (
      <>
        {content.images.map((image, index) => {
          if (index === 0 && omitFirst) {
            return null;
          }
          return (
            <div key={image} style={{
              textAlign: 'center',
            }}>
              <IdeallySizedImage image={image} index={index} />
            </div>
          );
        })}
      </>
    );
  }

  const GallerySlider = () => {

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      variableWidth: true
    };
    return (
      <div>
        <Slider {...settings}>
          {content.images.map((image, index) => {
            // first pic is used above
            if (index === 0) {
              return null;
            }
            return (
              <div key={image}>
                <img src={image}
                  alt=''
                  style={{
                    height: '90vh',
                    width: 'auto',
                    paddingRight: '5px',
                  }}
                />
                <div />
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }

  const VerticalLayout = () => {
    return (
      <div className='normalLayout'>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}>
          <PostText />
          <Gallery />
        </div>
      </div>
    );
  }

  const WideScreenLayout = () => {
    return (
      <div className='wideScreenLayout'>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          alignContent: 'end',
          width: '100%',
          paddingBottom: '5%',
        }}>
          <div style={{
            width: '50%',
          }}>
            {content.images[0]
              ? <img src={content.images[0]}
                alt=''
                style={{
                  padding: '3%',
                  paddingLeft: '15%',
                  width: '82%',
                  objectFit: 'contain',
                }} />
              : null}
          </div>
          <div className='postText' style={{
            flex: 1,
            paddingRight: '5%',
            paddingBottom: '1%',
            alignSelf: 'end',
            lineHeight: '28px'
          }}>
            <PostText />
          </div>
        </div>
        {content.images.length <= 2
          ? <Gallery omitFirst={true} />
          : <GallerySlider />}
      </div>);
  }

  const PostText = () => {
    return (
      <Squeeze>
        <h1>{content.title}</h1>
        <div>{content.paragraphs.map(
          (paragraph) => {
            return (
              <p key={paragraph}>
                {paragraph}
              </p>
            );
          }
        )}</div>
      </Squeeze>
    );
  }

  return (
    <PageWithMenu>
      <DKLogoHeader isSmall={content.title} />
      {useWindow().width > 1000
        ? <WideScreenLayout />
        : <VerticalLayout />}
      {content.videos && content.videos.length > 0
        ? <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <h1>Video{content.videos.length > 1 ? 's' : ''}</h1>
          {content.videos.map((src) => {
            return (
              <div key={src}>
                <Video src={src} autoplay={false} controlsAndSound={true} />
                <div style={{
                  paddingBottom: '24px',
                }}>
                  <DKLogoHeader isSmall={true} />
                </div>
              </div>
            );
          })}
        </div>
        : null}
    </PageWithMenu>
  );
}

export default Post;