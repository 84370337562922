//leatelierparis.com
// Oppolia
// Arclinea

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Design from './pages/Design';
import { useEffect, useState } from 'react';
import {
  getApplianceIds,
  getPostIds,
} from './utilities/storage';
import Post from './pages/Post';
import PortfolioAll from './pages/PortfolioAll';
import PortfolioCategories from './pages/PortfolioCategories';
import AboutPage from './pages/AboutPage';
// import ContactPage from './pages/ContactPage';

function App() {

  const [postIds, setPostIds] = useState([]);
  const [applianceIds, setApplianceIds] = useState([]);

  const homePage = <HomePage
    postIds={postIds}
    applianceIds={applianceIds}
  />

  useEffect(() => {
    setPostIds(getPostIds());
    setApplianceIds(getApplianceIds());
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={homePage} />

        {/* TODO: Remove development links */}
        {/* <Route path='/contact' element={<ContactPage />} /> */}

        <Route path='/about' element={<AboutPage />} />
        <Route path='/design' element={<Design />} />
        <Route path='/post/:id' element={<Post />} />
        <Route path='/portfolio' element={<PortfolioCategories />} />
        <Route path='/appliance/:id' element={<Post />} />
        <Route path='/appliances' element={<PortfolioAll
          ids={applianceIds} appliances={true} />} />
        <Route path='/all' element={<PortfolioAll
          ids={postIds} />} />

        {/* Instead of a 404 page, it returns to the homepage */}
        <Route path="*" element={homePage} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
