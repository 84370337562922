import { Squeeze } from "./containers";

export const Contact = () => {
  return (
    <address>
      <p>
        Contact us on <a href="tel:+353214373000">021 437 3000</a> or <a href="mailto:info@davidkiely.ie">info@davidkiely.ie</a> to discuss your bespoke kitchen or bedroom furniture.
        Our showrooms are open Monday to Friday 9am-5pm and Saturdays by appointment only (closed Sundays and bank holidays).
        We also meet clients outside of these times by prior appointment.
      </p>
    </address>
  );
}

const About = () => {
  return (
      <Squeeze>
        <p>
          David Kiely Kitchens was established in Cork in 1978 manufacturing fitted kitchens, bedroom and living room furniture.
          He expanded into the commercial arena fitting out hotel bedrooms, dining rooms, screened areas, and bar counters all over Munster.
        </p>
        <p>
          In 2003 David Kiely Kitchens moved to their new purpose-built showroom in Carrigaline Industrial Park, where we are located today.
          We manufacture bespoke kitchens, bedroom furniture, and interiors at our workshops located behind our showrooms.
          Our handmade bespoke kitchen doors and units are on display in our showroom.
          At David Kiely Kitchens we cater for all budgets, tastes and styles from traditional to contemporary.
        </p>
        <p>
          We are the Munster representatives for Christoff and have a very close relationship having worked on projects together for over 17 years.
          Christoff are a well-established Irish furniture company offering complete luxury bespoke kitchens and furniture.
          Christoff's head office is based in Dundalk and they have showrooms in Dublin, Belfast and Meath. Our latest Christoff display is now available for viewing at our showroom in Carrigaline.
        </p>
        <p style={{
          float: 'right',
          border: '1px solid black',
          textAlign: 'center',
          margin: '16px',
          padding: '24px',
          fontFamily: 'noto',
        }}>
          Design Kitchen Concepts LTD<br />trading as<br />David Kiely Kitchens & Interiors
        </p>          
        <p>
          We are also proud representatives for Aran Cucine, an Italian company that provide a range of traditional and contemporary style kitchens and furniture.
          We also have Aran Cucine on display in our showroom.
        </p>
        <p>
          When choosing a David Kiely Kitchen you can be guaranteed a quality product and personal design service.
          We advise on all aspects of your room design, including electrical, lighting, plumbing, quartz worktops and tiling finishes.
          We coordinate and manage the installation of your kitchen personally from start to completion.
          This is a turnkey service that you will not receive anywhere else.
        </p>
        <Contact />
      </Squeeze>
  );
}

export default About;