import About from "../components/About";
import DKLogoHeader from "../components/DKLogoHeader";
import PageWithMenu from "../components/PageWithMenu";

const AboutPage = () => {
  return (
    <PageWithMenu>
      <DKLogoHeader />
      <About />
    </PageWithMenu>
  );
}

export default AboutPage;