import IMAGE1 from './quooker.jpg';

export const post = {
  id: 'quooker',
  title: 'Quooker Tap',
  paragraphs: [
    'At David Kiely Kitchens we have a number of Quooker taps on display. A member of our team is always available to demonstrate the Quooker tap and explain the benefits.',
    'A Quooker consists of a kitchen tap on your worktop and a tank in your kitchen cupboard. There are a variety of options to choose from. You can have boiling, sparkling and filtered water.',
  ],
  images: [
    IMAGE1,
  ],
  products: [],
  videos: [
    'https://amplify-dkkmanage-dev-182554-deployment.s3.eu-west-1.amazonaws.com/videos/Quooker+tap+models.mp4',
  ],
}


