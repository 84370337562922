import { isMobile } from "react-device-detect";
import { DKKLinkButton, Squeeze } from "../components/containers";
import DKLogoHeader from "../components/DKLogoHeader";
import PageWithMenu from "../components/PageWithMenu";
import PostSummary from "../components/PostSummary";
import { getPostOrAppliance } from "../utilities/storage";



const PortfolioAllButton = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: '48px',
    }}>
      <DKKLinkButton to='/all'>
        <div style={{
          textAlign: 'center',
        }}>
        {isMobile ? 'Press' : 'Click'} here to see all portfolio items
        </div>
      </DKKLinkButton>
    </div>
  );
}


const PortfolioCategories = () => {

  return (
    <PageWithMenu>
      <Squeeze>
        <DKLogoHeader />
        <PortfolioAllButton />
        <h1 style={{
          textAlign: 'center',
          padding: 0,
          margin: 0,
        }}>
          Design Categories
        </h1>
        <PostSummary
          imposedTitle='Classic David Kiely'
          content={getPostOrAppliance('elibank')} />
        <PostSummary
          imposedTitle='Christoff'
          content={getPostOrAppliance('christoff')}
          isReversed={true} />
        <PostSummary
          imposedTitle='Contemporary'
          content={getPostOrAppliance('aran')} />
        <PortfolioAllButton />
      </Squeeze>
    </PageWithMenu>
  );
}

export default PortfolioCategories;