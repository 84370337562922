import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import './arrows.css';
import { useWindow } from "../../utilities/document";
import menuInfo from "../../utilities/menuInfo";
import { getPostOrAppliance } from "../../utilities/storage";
import { Contact } from "../About";
import { ContainerRow, DKKLinkButton, LinkWithoutFormatting, Squeeze } from "../containers";


const SideMenuContainer = () => {

  const sideRef = useRef();
  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {

    let ctx = gsap.context(() => {
      ScrollTrigger.create({
        trigger: '.container',
        start: 'top top',
        end: 'bottom 269px',
        pin: '.content',
      });
    }, sideRef);

    return () => ctx.revert();
  }, []);

  return (
    <div
      ref={sideRef}>
      <div className='container' style={{
        height: '970px',
      }}>
        <div className='content'>
          {menuInfo.map((menuItem) => {
            return (
              <LinkWithoutFormatting
                key={menuItem.item.text}
                to={menuItem.item.href}>
                <div style={{
                  fontSize: '32px',
                  padding: '16px',
                  borderBottom: '1px solid lightgray'
                }}>
                  {menuItem.item.text}
                </div>
              </LinkWithoutFormatting>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const Category = ({ category }) => {
  return (
    <div style={{
      position: 'relative',
      left: '12px',
      top: '40px',
      backgroundColor: 'white',
      fontSize: '12px',
      paddingLeft: '12px',
      paddingRight: '12px',
      borderRadius: '2px',
      display: 'inline-block',
    }}>
      {category}
    </div>
  );
}

const CarouselPic = ({ src, category, blurbWidth }) => {

  const picRef = useRef(null);

  return (
    <div
      className='carousel-pic-container'
      style={{
        height: blurbWidth > 400 ? '250px' : 250 / 400 * blurbWidth,
        width: Math.min(400, blurbWidth),
        overflow: 'hidden',
        borderRadius: '4px',
      }}>
      <Category category={category} />
      <img
        ref={picRef}
        alt=''
        src={src}
        onMouseEnter={() => {
          gsap.to(picRef.current, {
            zoom: 1.05,
            // transformOrigin: "50% 50%",
          });
        }}
        onMouseLeave={() => {
          gsap.to(picRef.current, {
            zoom: 1,
          })
        }}
        style={{
          height: blurbWidth > 400 ? '250px' : 250 / 400 * blurbWidth,
          width: Math.min(400, blurbWidth),
          objectFit: 'cover',
          transformOrigin: 'center center',
        }} />
    </div>
  );
}



const CarouselItem = ({ data, category, blurbWidth }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={
        () => {
          var baseUrl;
          if (category === 'Appliance') {
            baseUrl = '/appliance/'
          } else {
            baseUrl = '/post/'
          }
          navigate(baseUrl + data.id, { replace: true })
        }
      }
      style={{
        height: '320px',
        width: Math.min(424, blurbWidth),
        cursor: 'pointer',
      }}>
      <CarouselPic
        src={data.images[0]}
        category={category}
        blurbWidth={blurbWidth} />
      <div style={{
        fontWeight: 'bold',
        fontFamily: 'noto'
      }}>
        {data.title}
      </div>
      <div style={{
        fontSize: '16px',
        lineHeight: '20px',
      }}>
        {data.paragraphs[0].split('.')[0]}.
      </div>
    </div>
  );
}

const MainBody = ({ postIds, applianceIds }) => {

  const [blurbWidth, setBlurbWidth] = useState(0);
  const blurbRef = useRef();

  function CustomArrow({ className, style, onClick }) {

    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          height: '40px',
          width: '13px',
        }}
        onClick={onClick} />
    );
  }

  const Carousel = ({ itemIds, category }) => {
    const settings = {
      arrows: true,
      prevArrow: <CustomArrow />,
      nextArrow: <CustomArrow />,
      variableWidth: true,
    };
    return (
      <div
        className='carousel'
        style={{
          width: blurbWidth,
        }}>
        <Slider {...settings}>
          {itemIds.slice(0, 5).map((id) => {
            return (
              <div key={id}>
                <CarouselItem
                  data={getPostOrAppliance(id)}
                  category={category}
                  blurbWidth={blurbWidth} />
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }

  const Carousels = ({ postIds, applianceIds }) => {
    return (
      <div
        className='carousels'
        style={{
          marginLeft: '48px',
          marginRight: '48px',
        }}>
        <hr />
        <Carousel
          itemIds={postIds}
          category='Post' />
        <div style={{
          paddingBottom: '24px',
        }} >
          <hr />
        </div>
        <Carousel
          itemIds={applianceIds}
          category='Appliance' />
        <hr />
      </div>
    );
  }

  const TitleAndBlurb = () => {

    useEffect(() => {
      setBlurbWidth(blurbRef?.current?.clientWidth);

      function handleResize() {
        setBlurbWidth(blurbRef?.current?.clientWidth)
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const margins = {
      margin: '24px',
      marginLeft: '48px',
      marginRight: '48px',
    }

    return (
      <div className='title-and-blurb'
        style={{
          width: '100%',
        }}>
        <h1 style={{
          textAlign: 'center',
          fontFamily: 'noto',
          ...margins
        }}>
          David Kiely Kitchens
        </h1>
        <p ref={blurbRef}
          style={{
            ...margins
          }}>
          Established in Cork in 1978, David Kiely Kitchens manufacture bespoke kitchens, bedroom furniture, and interiors. Our personal design service guarantees a quality product.
        </p>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          paddingBottom: '16px',
        }}>
          <DKKLinkButton
            style={{
              marginRight: '48px',
            }}
            to='/about'>
            About David Kiely Kitchens
          </DKKLinkButton>
        </div>
      </div>
    );
  }

  return (
    <div >
      <TitleAndBlurb />
      <Carousels postIds={postIds} applianceIds={applianceIds} />
    </div>
  );
}

const NewFrontArea = ({ postIds, applianceIds }) => {
  return (
    <>
      <ContainerRow style={{
        width: useWindow().width,
      }}>
        {useWindow().width > 800
          ? <div style={{
            width: '25%'
          }}>
            <SideMenuContainer />
          </div>
          : null}
        <div style={{
          width: useWindow().width > 800 ? '75%' : '100%',
        }}>
          <MainBody
            postIds={postIds}
            applianceIds={applianceIds} />
        </div>
      </ContainerRow>
      <Squeeze>
        <Contact />
      </Squeeze>
    </>
  );
}

export default NewFrontArea;