import MainPic from "../components/mainpic/MainPic";
import {
  useWindow,
} from "../utilities/document";
import DKLogoWithCaption from './images/logo-with-caption-black-on-white-400.png';
import PageWithMenu from "../components/PageWithMenu";
import DKKPromoVideo from "../components/DKKPromoVideo";
import NewFrontArea from "../components/newFrontArea/NewFrontArea";

export const LogoWithCaption = ({ style }) => {
  return (
    <img src={DKLogoWithCaption}
    alt=''
    style={{
      width: '14%',
      minWidth: useWindow().width > 600 ? '128px' : '240px',
      // '72px'
      position: useWindow().width > 600 ? 'absolute' : 'relative',
      top: '10px',
      left: useWindow().width / 2,
      transform: 'translate(-50%)',
      ...style,
    }} />
  );
}

const HomePageContent = ({ postIds, applianceIds }) => {

  return (
    <PageWithMenu noMenu={useWindow().width > 800}>
      <div className='topPic'>
        <MainPic />
        <LogoWithCaption />
      </div>
      <NewFrontArea
        postIds={postIds}
        applianceIds={applianceIds} />
      <div className='videoFooter'
        style={{
          paddingTop: '24px',
        }}>
        <DKKPromoVideo />
      </div>
    </PageWithMenu>
  );
}

export default HomePageContent;