import { ContainerRow, } from './containers';
import menuInfo from '../utilities/menuInfo';
import { Link, useLocation } from 'react-router-dom';
import React from 'react';


const MenuScrolled = React.forwardRef((props, ref) => {

  const currentPath = useLocation().pathname;

  return (
    <ContainerRow
      ref={ref}
      style={{
        position: 'fixed',
        top: '0px',
        left: '0px',
        backgroundColor: '#202020',
        paddingTop: '12px',
        paddingBottom: '12px',
        // hidden until scroll up
        transform: 'translate(0, -100%)',
        justifyContent: 'space-around'
      }}>
      {menuInfo.map((itemInfo, index) => {
        if (currentPath === itemInfo.item.href) return null;
        return (
          <div key={index}>
            <Link
              to={itemInfo.item.href}
              style={{
                color: 'white',
                textDecoration: 'none',
                fontSize: '16px',
              }}>
              {itemInfo.item.text}
            </Link>
          </div>
        );
      })}
    </ContainerRow>
  );
});

export default MenuScrolled;