import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { useNavigate } from 'react-router-dom';
import { useWindow } from '../../utilities/document';
import Pic1 from './1.jpg';
import Pic2 from './2.jpg';
import Pic3 from './3.jpg';
import Pic4 from './4.jpg';
import Pic5 from './5.jpg';
import Pic6 from './6.jpg';
import BlueKitchen1 from '../../tmp/images/tmp-post-dkk-1-1.jpg'
import BlueKitchen2 from '../../tmp/images/tmp-post-dkk-1-2.jpg'
import BlueKitchen3 from '../../tmp/images/tmp-post-dkk-1-3.jpg'

const images = [
  BlueKitchen1,
  Pic1,
  BlueKitchen2,
  Pic2,
  BlueKitchen3,
  Pic3,
  Pic4,
  Pic5,
  Pic6,
]

const MainPic = () => {
  const frontPicRef = useRef(null);
  const behindPicRef = useRef(null)
  const frontPicIndexRef = useRef(0);
  const behindPicIndexRef = useRef(1);
  const frontPicVisibleRef = useRef(true);

  const navigate = useNavigate();

  function handleClick() {
    navigate('/portfolio', { replace: true });
  }

  useLayoutEffect(() => {

    const picChangeInterval = setInterval(() => {

      if (frontPicVisibleRef.current) {
        gsap.to(frontPicRef.current, {
          opacity: '0',
          duration: 1,
        }).then(() => {
          frontPicVisibleRef.current = false;
          var newFrontPicIndex = (behindPicIndexRef.current + 1) % images.length;
          frontPicIndexRef.current = newFrontPicIndex;
          gsap.set(frontPicRef.current, { attr: { 'src': images[newFrontPicIndex] } });
        })
      } else {
        gsap.to(frontPicRef.current, {
          opacity: '1',
          duration: 1,
        }).then(() => {
          frontPicVisibleRef.current = true;
          var newBehindPicIndex = (frontPicIndexRef.current + 1) % images.length;
          behindPicIndexRef.current = newBehindPicIndex;
          gsap.set(behindPicRef.current, { attr: { 'src': images[newBehindPicIndex] } });
        })
      }

    }, 6000);

    return () => clearInterval(picChangeInterval);
  }, []);

  return (
    <div onClick={handleClick} style={{
      cursor: 'pointer',
      // need to keep container tall while images load
      maxHeight: 590 * (useWindow().width / 1500),
    }}>
      <div
        className='mainBehindPic'
        style={{
          maxHeight: 590 * (useWindow().width / 1500),
          position: 'relative',
          top: '0px',
          left: '0px',
          overflow: 'hidden',
        }}>
        <img
          src={images[1]}
          width='100%'
          alt=''
          ref={behindPicRef}
          style={{
            objectFit: 'contain',
            transform: 'translate(0, -20%)'
          }}
        />
      </div>
      <div
        className='mainFrontPic'
        style={{
          maxHeight: 590 * (useWindow().width / 1500),
          position: 'absolute',
          top: '0px',
          left: '0px',
          overflow: 'hidden',
        }}>
        <img
          src={images[0]}
          width='100%'
          alt=''
          ref={frontPicRef}
          style={{
            opacity: '1',
            objectFit: 'contain',
            transform: 'translate(0, -20%)'
          }}
        />
      </div>
    </div>
  );
}

export default MainPic;