import { useNavigate } from 'react-router-dom';
import DKLogo from '../pages/images/logo-with-border-transparency-160.png';

const DKLogoHeader = ({ isSmall }) => {

  const navigate = useNavigate();

  return (
    <div
    onClick={() => navigate('/', { replace: true })}
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '16px',
      cursor: 'pointer',
    }}>
    <img src={DKLogo} alt='' style={{
      width: isSmall ? '40px': '160px',
      height: isSmall? '40px': '160px',
    }} />
    </div>
  );
}

export default DKLogoHeader;