import { PageContainer } from "./containers";
import Menu from "../components/DropDownMenu";
import MenuPrompt from "./MenuPrompt";
import MenuScrolled from "./MenuScrolled";
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useEffect, useRef } from "react";
import { useState } from "react";

const PageWithMenu = ({ children, noMenu }) => {

  const [menuVisible, setMenuVisible] = useState(false);
  const menuScrolledRef = useRef();
  const topRef = useRef();

  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {

    var firstLoad = true;

    ScrollTrigger.create({
      start: 'top top',
      end: 99999,
      onUpdate: (self) => {
        if (menuScrolledRef.current) {
          if ((topRef.current && topRef.current.getBoundingClientRect().top >= 0)
            || self.direction === -1) {
            gsap.to(menuScrolledRef.current, {
              yPercent: -100,
              duration: 0.2,
            });
          } else {
            // necessary so the scroll menu will not appear the moment you
            // return to a scrolled page
            if (!firstLoad) {
              gsap.to(menuScrolledRef.current, {
                yPercent: 100,
                duration: 0.2,
              });  
            }
            firstLoad = false;
          }
        }
      }
    });

  }, []);

  return (
    <PageContainer>
      <div ref={topRef} className='topRefLocation' >
        {children}
        {noMenu ? null :
          <>
            <MenuPrompt setMenuVisible={setMenuVisible} />
            <MenuScrolled
              ref={menuScrolledRef} />
            {menuVisible
              ? <Menu setMenuVisible={setMenuVisible} />
              : null}
          </>
        }
      </div>
    </PageContainer>
  );
}

export default PageWithMenu;