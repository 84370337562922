import { Link } from "react-router-dom";
import GalleyLayoutImage from './images/layouts/GalleyLayout.png';
import OpenLayoutImage from './images/layouts/OpenLayout.png';
import LShapeLayoutImage from './images/layouts/LShapeLayout.png';
import UShapeLayoutImage from './images/layouts/UShapeLayout.png';
import { BackgroundVideo, ContainerFullWidth, Squeeze } from "../components/containers";
import PageWithMenu from "../components/PageWithMenu";

const designSummaryText = 'Whether you are renovating an existing kitchen or starting your '
  + 'new build journey, a well-thought-out plan is necessary to achieve success. '
  + 'David and his design team will work with you to create a plan, custom-designed '
  + 'to suit your needs. '
  + 'When designing a kitchen there are many things to bear in mind.';

export const DesignPageSummary = () => {

  return (
    <div style={{
      // position: 'absolute'
    }}>
      <p>
        {designSummaryText}
      </p>
    </div>
  );
}

const Design = () => {

  const KitchenDesignServices = () => {
    return (
      <>
        <BackgroundVideo heading={'Kitchen Design Services'} text={designSummaryText} />
        <Squeeze>
          <p>
            Allow enough time to discuss, plan and revise the design until you are satisfied that you have the kitchen of your dreams.
            Start with  the big picture &mdash; envisage how you and your family will use the kitchen &mdash; for family meals, informal dinner parties, catching up on work, or a place where children can do their homework.
            Practicality, functionality and of course style, are all very important elements when designing your kitchen.
          </p>
          <p>
            There are some standard kitchen layouts below. We would love you to contact us to discuss your kitchen plans.
          </p>
          <p style={{
            paddingBottom: '24px',
          }}>
            Telephone <a href="tel:+353214373000">021 437 3000</a> or email <a href="mailto:info@davidkiely.ie">info@davidkiely.ie</a>.
          </p>
        </Squeeze>
      </>
    );
  }

  const Renovating = () => {
    return (
      <>
        <h2>
          Renovating Existing Kitchens
        </h2>
        <p>
          If you would like to renovate an existing kitchen we would be delighted to discuss your plans with you.
        </p>
        <p>
          Renovation of existing kitchens can work well when planned and designed with our creative designer.
        </p>
      </>
    );
  }

  const KitchenLayout = (props) => {
    return (
      <div style={{
        display: "inline-block",
        width: '46%',
        minWidth: '300px',
        margin: '2%',

      }} >
        <img alt={props.title} src={props.src} style={{
          maxWidth: '100%',
        }} />
        <h3>{props.title}</h3>
        <p>{props.text}</p>
        <hr />
      </div>
    )
  }

  const GalleyStyleLayout = () => {
    return (
      <KitchenLayout
        src={GalleyLayoutImage}
        title={'Galley Style Layout'}
        text={'This layout makes the best use of limited space in a narrow room or where the door and window are on opposite sides. Cabinets and appliances are positioned so as to optimise space, functionality and work flow in the kitchen.'}
      />
    );
  }

  const OpenPlanLayout = () => {
    return (
      <KitchenLayout
        src={OpenLayoutImage}
        title={'Open Plan Layout'}
        text={'One large area is created by having the kitchen flow into another living space - generally the living room. An open plan layout is family friendly and ideal for entertaining - allowing the cook to interact with guests while preparing food.'}
      />
    );
  }

  const LShapeLayout = () => {
    return (
      <KitchenLayout
        src={LShapeLayoutImage}
        title={'L-shape Layout'}
        text={'A popular kitchen design suitable for small or large kitchens where a breakfast bar or dining table can be added. This shape generally provides good traffic flow in a busy kitchen.'}
      />
    );
  }

  const UShapeLayout = () => {
    return (
      <KitchenLayout
        src={UShapeLayoutImage}
        title={'U-shape Layout'}
        text={'A U-shaped kitchen design generally provides ample countertop workspace and three walls for cabinet storage and fitted appliances. This kitchen layout tends to create a working area that is very efficient.'}
      />
    );
  }

  const Layouts = () => {
    return (
      <>
        <GalleyStyleLayout />
        <OpenPlanLayout />
        <LShapeLayout />
        <UShapeLayout />
      </>

    );
  }

  return (
    <PageWithMenu>
      <ContainerFullWidth>
        <KitchenDesignServices />
        <Squeeze>
          <Renovating />
          <Layouts />
          <p>
            <Link to={'../'}>Home</Link>
          </p>
        </Squeeze>
      </ContainerFullWidth>
    </PageWithMenu>
  );
}

export default Design;