import { useWindow } from "../utilities/document";
import { Video } from "./containers";


const DKKPromoVideo = () => {

  return (
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Video
          src='https://amplify-dkkmanage-dev-182554-deployment.s3.eu-west-1.amazonaws.com/DKK-promo-video.mp4'
          autoplay={true} />
          {/* To remove black line at bottom of video */}
        <div style={{
          width: Math.min(useWindow().width, 785),
          height: '5px',
          backgroundColor: 'white',
          transform: 'translate(0, -14px)'
        }} />
      </div>
    </>
  );
}

export default DKKPromoVideo;