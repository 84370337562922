import IMAGE1 from '../images/tmp-post-dkk-3-1.jpg'
import IMAGE2 from '../images/tmp-post-dkk-3-2.jpg'
import IMAGE3 from '../images/tmp-post-dkk-3-3.jpg'
import IMAGE4 from '../images/tmp-post-dkk-3-4.jpg'
import IMAGE5 from '../images/tmp-post-dkk-3-5.jpg'

export const post = {
  id: 'christoff',
  title: 'Hand Painted Christoff Kitchen',
  paragraphs: [
    'We are delighted to show you this amazing Inframe, hand painted Christoff Kitchen by David Kiely Kitchens. The main kitchen is hand painted in the elegant schoolhouse white and the Island is painted in off black which creates a fabulous contrast in this Kitchen. The worktop is Eternal glow by silestone and this display also features the fabulous Lacanche range',
    'This display is one of our latest displays in our showroom and I\'m sure you will agree this is an amazing Kitchen!',
  ],
  images: [
    IMAGE1,
    IMAGE2,
    IMAGE3,
    IMAGE4,
    IMAGE5,
  ],
  products: [],
  videos: [],
}


