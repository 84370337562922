import IMAGE1 from '../images/tmp-post-dkk-1-1.jpg'
import IMAGE2 from '../images/tmp-post-dkk-1-2.jpg'
import IMAGE3 from '../images/tmp-post-dkk-1-3.jpg'
import IMAGE4 from '../images/tmp-post-dkk-1-4.jpg'
import IMAGE5 from '../images/tmp-post-dkk-1-5.jpg'
import IMAGE6 from '../images/tmp-post-dkk-1-6.jpg'

export const post = {
  id: 'elibank',
  title: 'Stunning Kitchen Design',
  paragraphs: [
    'Another stunning kitchen design by David Kiely Kitchens. The main Kitchen is hand painted in the earthy and calm Purbeck stone. The island is hand painted in the rich Downpipe.',
    'Some of my favorite parts of this kitchen is the bespoke built in bar area which incorporates a drinks fridge and wine cooler. The LED sensor lighting also creates a nice ambiance in the evenings which turns this kitchen into a more sociable space for gatherings.',
    'The walk in pantry is also a fantastic addition to this Kitchen and a must for any busy family!'
  ],
  images: [
    IMAGE1,
    IMAGE2,
    IMAGE3,
    IMAGE4,
    IMAGE5,
    IMAGE6,
  ],
  products: [],
  videos: [],
}


