import { gsap } from "gsap";
import { useLayoutEffect, useRef } from "react";
import Logo192 from '../pages/images/logo192.png';
import ExitIcon from '../pages/images/icon-exit.png';
import { useWindow } from "../utilities/document";
import menuInfo from "../utilities/menuInfo";
import MenuItem from './DropDownMenuItem';
import { useLocation } from "react-router-dom";


const Menu = ({ setMenuVisible }) => {

  const menuRef = useRef();
  const dimmerRef = useRef();
  const currentPath = useLocation().pathname;

  useLayoutEffect(() => {
    gsap.fromTo(
      menuRef.current, {
      scaleY: '0',
      transformOrigin: 'top',
    },
      {
        scaleY: '1',
        duration: 0.5,
      });
      gsap.fromTo(
        dimmerRef.current, {
        opacity: '0',
      },
        {
          opacity: '0.5',
          duration: 0.5,
        });
      
    // eslint-disable-next-line
  }, []);

  function remove() {
    gsap.to(
      menuRef.current, {
        scaleY: '0',
        duration: 0.5,
        transformOrigin: 'top',
      }
    );
    gsap.to(
      dimmerRef.current, {
        opacity: '0',
        duration: 0.5,
      }
    ).then(() => setMenuVisible(false));
  }

  return (
    <>
      <div
        className='wholeScreenDimmer'
        ref={dimmerRef}
        onClick={() => remove()}
        style={{
          minWidth: '100%',
          minHeight: '100%',
          position: 'fixed',
          top: '0px',
          left: '0px',
          backgroundColor: '#101010',
          fontColor: 'white',
        }} />
      <div className='menu'
        ref={menuRef}
        style={{
          position: 'fixed',
          width: '100%',
          top: '0px',
          left: '0px'
        }}>
        <div
          style={{
            width: '100%',
            backgroundColor: '#101010',
            top: '0px',
            left: '0px',
          }}>
          <img src={Logo192} width='70px' alt='' style={{
            margin: '10px',
            top: '10px',
            marginLeft: useWindow().width / 2,
            transform: 'translate(-50%)',
          }} />
          <img src={ExitIcon}
            alt=''
            onClick={() => remove()}
            style={{
            width: '24px',
            height: '24px',
            float: 'right',
            margin: '20px'
          }} />
        </div>
        <div
          style={{
            top: '100px',
            width: '100%',
            backgroundColor: '#101010',
          }}>
          {menuInfo
            ? menuInfo.map((itemInfo, index) => {
              if (currentPath === itemInfo.item.href) return null;
              return (
                <MenuItem key={index}
                  text={itemInfo.item.text}
                  href={itemInfo.item.href} />
              );
            })
            : null}
        </div>
      </div>
    </>
  );
}

export default Menu;