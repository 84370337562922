import IMAGE1 from '../images/AranCucine-MascaLaccata.png'

export const post = {
  id: 'aran-masca',
  title: 'Masca Laccata from Aran Cucine',
  paragraphs: [
    'This fabulous contemporary handleless kitchen with clean lines is sleek, bright and elegant and will never go out of style!',
  ],
  images: [
    IMAGE1,
  ],
  products: [],
  videos: [],
}


