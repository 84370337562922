import {
  useWindow,
} from "../utilities/document";
import DKKBurger from '../pages/images/dkk-burger.png';


const MenuPrompt = ({ setMenuVisible }) => {
  return (
    <div className='menuPrompt'
      onClick={() => setMenuVisible(true)}
      style={{
        position: 'absolute',
        top: '10px',
        left: useWindow().width / 20,
        opacity: '75%',
        cursor: 'pointer',
      }}>
      {useWindow().width > 600
        ? <div className='menuWord'
          style={{
            fontFamily: 'Roboto',
            fontSize: '25px',
            color: 'white',
            padding: '4px 8px 4px 8px',
            backgroundColor: '#000000CC',
          }}>
          MENU
        </div>
        : <div className='dkkBurger'>
          <img src={DKKBurger} alt=''
            style={{ width: '40px', height: '40px' }} />
        </div>
      }
    </div>
  );
}

export default MenuPrompt;