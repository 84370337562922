import IMAGE1 from './tmp-post-dkk-lacanche-1.jpg';

export const post = {
  id: 'lacanche',
  title: 'Lacanche',
  paragraphs: [
    // 'We are a proud agent for Lacanche. Each Lacanche is individually made to a specific order, based initially on choices from a huge variety of models, options and finishes that can be tailored to your requirements. We have two fabulous Lacanche range cookers on display in our showroom.',
    'We are a proud dealer of Lacanche range cookers. Our clients can tailor their range cookers to suit their needs. Every Lacanche is handmade in the heart of Burgundy, in the small village of Lacanche. Each is made to your specification. You can choose the colour, configuration and finish to suit your kitchen design.'
  ],
  images: [
    IMAGE1,
  ],
  products: [],
  videos: [
    'https://amplify-dkkmanage-dev-182554-deployment.s3.eu-west-1.amazonaws.com/videos/Film_lacanche_16_9.mp4',
    'https://amplify-dkkmanage-dev-182554-deployment.s3.eu-west-1.amazonaws.com/videos/LeFilm_v3_768.mp4',
  ],
}


