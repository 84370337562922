import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";


const MenuItem = ({ href, text }) => {

  const [mouseHovering, setMouseHovering] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <div
        onMouseOver={() => setMouseHovering(true)}
        onMouseLeave={() => setMouseHovering(false)}
        onClick={() => navigate(href, {replace: true})}
        style={{
          backgroundColor: mouseHovering ? '#303030' : '#101010',
          padding: '12px',
          paddingLeft: '48px',
        }}>
        <Link to={href}
        style={{
          fontSize: '24px',
          color: 'white',
          textDecorationLine: 'none' }}>
            {text}
        </Link>
      </div>
      <div style={{
        minHeight: '1px',
        backgroundColor: '#222222',
      }}></div>
    </>

  );
}

export default MenuItem;