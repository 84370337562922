function menuItem(text, href) {
  return {
    text: text,
    href: href,
  }
}

const menuInfo = [
  { 'item': menuItem('Home', '/') },
  {
    'item': menuItem('Design', '/design'),
    // 'subitems': [menuItem] - currently not deployed
  },
  { 'item': menuItem('Portfolio', '/portfolio') },
  { 'item': menuItem('Appliances', '/appliances') },
  { 'item': menuItem('About', '/about') },
];

export default menuInfo;