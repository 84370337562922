// Bring over yourapp.ie dev folder
// Get two panel container
// Margins in at sides of all summaries
// Square pic
// Centre sample pic


import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContainerRow, Squeeze } from "../components/containers";
import DKLogoHeader from "../components/DKLogoHeader";
import PageWithMenu from "../components/PageWithMenu";
import PostSummary from "../components/PostSummary";
import {
  getPostOrAppliance,
  getPersistedNumberOfPostsDisplayed,
  persistNumberOfPostsDisplayed,
  getPersistedFirstPostIndexDisplayed,
  persistFirstPostIndexDisplayed,
  getPersistedFirstApplianceIndexDisplayed,
  persistFirstApplianceIndexDisplayed,
  getPersistedNumberOfAppliancesDisplayed,
  persistNumberOfAppliancesDisplayed,
} from "../utilities/storage";

const PortfolioAll = ({ ids, areAppliances }) => {


  //First index =============================================
  const locallySavedFirstIndex = areAppliances
    ? getPersistedFirstApplianceIndexDisplayed()
    : getPersistedFirstPostIndexDisplayed();

  const [firstIdIndexDisplayed, setFirstIdIndexDisplayed]
    = useState(locallySavedFirstIndex);

  function globallyChangeFirstPostIdIndexDisplayed(index) {
    setFirstIdIndexDisplayed(index);
    if (areAppliances) {
      persistFirstApplianceIndexDisplayed(index);
    } else {
      persistFirstPostIndexDisplayed(index);
    }
  }

  //Number displayed ========================================
  var locallySavedNumItems = areAppliances
    ? getPersistedNumberOfAppliancesDisplayed()
    : getPersistedNumberOfPostsDisplayed();
  if (locallySavedNumItems > ids.length) {
    locallySavedNumItems = ids.length;
  }
  if (locallySavedNumItems === 0) {
    locallySavedNumItems = Math.min(5, ids.length);
  }
  const [numItemsDisplayed, setNumItemsDisplayed]
    = useState(locallySavedNumItems);

  function globallyChangeNumberofItemsDisplayed(num) {
    setNumItemsDisplayed(num);
    if (areAppliances) {
      persistNumberOfAppliancesDisplayed(num);
    } else {
      persistNumberOfPostsDisplayed(num);
    }
  }

  // these should be in ascending order
  const displayChoices = [5, 10, 20, 50];
  const [displayedItems, setDisplayedItems] = useState([]);
  const navigate = useNavigate();

  function moveToPreviousList() {
    if (firstIdIndexDisplayed > numItemsDisplayed) {
      globallyChangeFirstPostIdIndexDisplayed(firstIdIndexDisplayed - numItemsDisplayed);
    } else {
      globallyChangeFirstPostIdIndexDisplayed(0);
    }
  }

  useState(() => {
    if (firstIdIndexDisplayed + numItemsDisplayed >= ids.length) {
      moveToPreviousList();
    }
  }, [firstIdIndexDisplayed]);

  function moveToNextList() {
    if (firstIdIndexDisplayed + (numItemsDisplayed * 2) < ids.length) {
      globallyChangeFirstPostIdIndexDisplayed(firstIdIndexDisplayed + numItemsDisplayed);
    } else {
      globallyChangeFirstPostIdIndexDisplayed(ids.length - numItemsDisplayed);
    }
  }

  const Buttons = () => {

    return (
      <div>
        {numItemsDisplayed < ids.length
          ? <p>
            Displaying items {firstIdIndexDisplayed + 1} to {firstIdIndexDisplayed + numItemsDisplayed}
          </p>
          : null}
        <ContainerRow>
          {firstIdIndexDisplayed > 0
            ? <button onClick={() => moveToPreviousList()} style={{
              fontSize: '16px',
            }}>
              PREVIOUS
            </button>
            : null}
          {firstIdIndexDisplayed + numItemsDisplayed < ids.length
            ? <button onClick={() => moveToNextList()} style={{
              fontSize: '16px',
            }}>
              NEXT
            </button>
            : null}
        </ContainerRow>
      </div>
    );
  }

  function changeNumberDisplayedSafely(requestedNumberDisplayed) {
    // possibly too big if number of posts available has declined since
    // storing locally in last user session
    if (requestedNumberDisplayed > ids.length) {
      requestedNumberDisplayed = ids.length;
    }
    // move back first item displayed if number requested would go off the end
    if (firstIdIndexDisplayed + requestedNumberDisplayed > ids.length) {
      globallyChangeFirstPostIdIndexDisplayed(ids.length - requestedNumberDisplayed);
    }
    globallyChangeNumberofItemsDisplayed(requestedNumberDisplayed);
  }


  useEffect(() => {

    if (numItemsDisplayed === 0) {
      const proposedNumItemsDisplayed = Math.min(5, ids.length);
      globallyChangeFirstPostIdIndexDisplayed(0);
      globallyChangeNumberofItemsDisplayed(proposedNumItemsDisplayed)
      setDisplayedItems(ids.slice(0, proposedNumItemsDisplayed));
      return;
    }
    setDisplayedItems(ids
      .slice(firstIdIndexDisplayed,
        firstIdIndexDisplayed + numItemsDisplayed));
    // eslint-disable-next-line
  }, [ids, firstIdIndexDisplayed, numItemsDisplayed]);


  return (
    <PageWithMenu>
      <DKLogoHeader />
      <Squeeze>
        <ContainerRow style={{
          marginTop: '8px',
        }}>
          {numItemsDisplayed
            ? <div>
              {numItemsDisplayed < ids.length
                ? <p>
                  Currently viewing {numItemsDisplayed} of {ids.length} {areAppliances ? 'appliances' : 'posts'}.
                </p>
                : null}
              <ContainerRow>
                {          // More than one choice
                  numItemsDisplayed >= displayChoices[1]
                    ? <>
                      <p>
                        Number of items to display:&nbsp;&nbsp;
                      </p>
                      <p>
                        {
                          displayChoices.map((choice, index) => {
                            if (choice > ids.length) return null;
                            return (
                              <span key={choice}
                                onClick={() => changeNumberDisplayedSafely(choice)}
                                style={{
                                  cursor: 'pointer',
                                }}>
                                {index !== 0 ? ' | ' : null}
                                <span
                                  style={{
                                    // string returned from local storage
                                    textDecoration: '' + choice === '' + numItemsDisplayed
                                      ? 'underline' : 'none',
                                  }}>
                                  {choice}
                                </span>
                              </span>
                            );
                          })
                        }
                      </p>
                    </>
                    : null}
              </ContainerRow>
              <Buttons />
            </div>
            : null}
        </ContainerRow>
        {displayedItems
          ? displayedItems.map((id, index) => {
            return (
              <div onClick={
                () => navigate((areAppliances
                  ? '/appliance/'
                  : '/post/'
                ) + id, { replace: true })
              }
                key={id}
                style={{
                  cursor: 'pointer',
                }}>
                <PostSummary
                  content={getPostOrAppliance(id)}
                  isReversed={index % 2 === 1} />
                <DKLogoHeader isSmall={true} />
              </div>
            );
          })
          : null}
        {ids
          ? <Buttons />
          : null}
      </Squeeze>
    </PageWithMenu>
  );
}

export default PortfolioAll;
