import { useLayoutEffect, useRef } from "react";

const WorkShopVideo = ({ videoScreenWidth, recordVideoHeight }) => {

  const videoRef = useRef(undefined);


  useLayoutEffect(() => {
    videoRef.current.defaultMuted = true;
    recordVideoHeight(videoRef.current.clientHeight);
    // videoRef.current.load();
    // videoRef?.current?.play();

    function handleResize() {
      recordVideoHeight(videoRef?.current?.clientHeight);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="videoContainer">
      <div style={{
        position: 'absolute',
        minHeight: videoRef?.current?.clientHeight,
        backgroundColor: 'gray',
        minWidth: videoScreenWidth,
      }}>&nbsp;</div>
      <video
        ref={videoRef}
        style={{
          width: '100%',
          // maxWidth: videoScreenWidth,
          objectFit: 'cover',
          opacity: '0.5',
          display: 'block',
        }}
        // making double sure loaded height is sent back
        onPlay={() => recordVideoHeight(videoRef.current.clientHeight)
        }
        autoPlay={true} loop muted playsInline>
        <source
          src='https://amplify-dkkmanage-dev-182554-deployment.s3.eu-west-1.amazonaws.com/dkk-workshop-portrait-final.mp4'
          type="video/mp4" />
      </video>
    </div>
  );
}

export default WorkShopVideo;