import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWindow } from "../utilities/document";
import DKLogoHeader from "./DKLogoHeader";

const PostSummary = ({
  content,
  followingLogo,
  imposedTitle,
  isAppliance,
  isReversed,
}) => {

  const textAreaRef = useRef();
  const navigate = useNavigate();


  const AbbreviatedText = () => {

    return (
      <div className='abbreviatedText'
        ref={textAreaRef}>
        <h1 style={{
          fontFamily: 'RobotoSlab'
        }}>
          {imposedTitle ? imposedTitle : content.title}
        </h1>
        {content.paragraphs && content.paragraphs.length > 0
          ? <>
            {content.paragraphs[0]}
            {content.paragraphs.length > 1
              ? <span style={{
                fontStyle: 'oblique',
              }}>
                ... (more)
              </span>
              : null}
          </>
          : null}
      </div>
    );
  }
  const thumbnails = content.images.slice(0, 3);

  const SquareThumbnailArea = () => {

    const [height, setHeight] = useState(0);
    const areaRef = useRef();

    const dimensions = [
      [],
      [
        {
          width: 1,
          height: 1
        },
      ],
      [
        {
          width: .5,
          height: 1
        },
        {
          width: .5,
          height: 1
        },
      ],
      [
        {
          width: .5,
          height: 1
        },
        {
          width: .5,
          height: .5
        },
        {
          width: .5,
          height: .5
        },
      ]
    ]

    useEffect(() => {

      function handleResize() {
        setHeight(areaRef.current.getBoundingClientRect().width - 5);
      }

      if (areaRef.current) {
        handleResize();
      }
      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize);
      }
    }, []);

    return (
      <div className='squareThumbnailArea'
        ref={areaRef}
        style={{
          minHeight: height,
          maxHeight: height,
          margin: '24px',
        }}>
        {thumbnails.map((thumbnail, index) => {
          return (
            <div
              key={thumbnail}
              style={{
                minWidth: height * dimensions[thumbnails.length][index]['width'],
                minHeight: height * dimensions[thumbnails.length][index]['height'],
                maxWidth: height * dimensions[thumbnails.length][index]['width'],
                maxHeight: height * dimensions[thumbnails.length][index]['height'],
                // backgroundColor: '#' + (999 * (index + 1)),
                display: 'inline',
                float: 'left',
                backgroundImage: `url(${thumbnail})`,
                backgroundSize: 'cover',
              }} />
          );
        })}
      </div>
    );
  }

  const FullWidthThumbnailArea = () => {
    return (
      <div className='fullWidthThumbnailArea'
        style={{
          minWidth: '100%',
          minHeight: '100px',
          maxHeight: '100px',
          backgroundColor: 'gray',
          overflow: 'hidden',

        }}>
        {thumbnails.map((thumbnail, index) => {
          return (
            <div
              key={thumbnail}
              style={{
                minWidth: 100 / thumbnails.length + '%',
                maxWidth: 100 / thumbnails.length + '%',
                minHeight: '100px',
                maxHeight: '100px',
                display: 'inline',
                float: 'left',
                backgroundImage: `url(${thumbnail})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }} />
          );
        })}

      </div>
    );

  }

  const WideScreenTextArea = () => {
    return (
      <div style={{
        minWidth: '62%',
        alignSelf: 'center',
        paddingRight: '3%',
      }}>
        <AbbreviatedText />
      </div>
    );
  }

  const WideScreenThumbnailArea = () => {
    return (
      <div style={{
        maxWidth: '33%',
        minWidth: '33%',
      }}>
        <SquareThumbnailArea />
      </div>
    );
  }

  const WideScreenContentArea = () => {
    return (
      content.paragraphs && content.paragraphs.length > 0
        ?
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
        }}>
          {isReversed
            ? <>
              <WideScreenThumbnailArea />
              <WideScreenTextArea />
            </>
            : <>
              <WideScreenTextArea />
              <WideScreenThumbnailArea />
            </>}
        </div>
        : <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
          <div style={{
            maxWidth: '33%',
            minWidth: '33%',
          }}>
            <SquareThumbnailArea />
          </div>
        </div>
    );
  }

  const NarrowScreenContentArea = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        <AbbreviatedText />
        <FullWidthThumbnailArea />
      </div>
    );
  }


  return (
    <div className='postSummary'
      onClick={
        () => {
          var baseUrl;
          if (isAppliance) {
            baseUrl = '/appliance/'
          } else {
            baseUrl = '/post/'
          }
          navigate(baseUrl + content.id, { replace: true })
        }
      }
      style={{
        cursor: 'pointer',
      }}>
      {useWindow().width > 800
        ? <WideScreenContentArea />
        : <NarrowScreenContentArea />}
      {
        followingLogo
          ? <DKLogoHeader isSmall={true} />
          : null
      }
    </div >
  );
}

export default PostSummary;