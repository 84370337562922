import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useWindow } from "../utilities/document";
import DKLogoHeader from "./DKLogoHeader";
import WorkShopVideoContainer from "./WorkshopVideo";
import YourAppDotIeFooter from "./YourAppDotIEFooter";

export const BackgroundVideo = ({ heading, text }) => {


  const TextArea = () => {
    return (
      <div className='textDiv' style={{
        alignSelf: 'center',
      }}>
        <Squeeze>
          <DKLogoHeader isSmall={true} />
          <p>
            {text}
          </p>
        </Squeeze>
      </div>
    );
  }

  const TitleOverVideo = ({ videoScreenWidth }) => {
    const [videoHeight, setVideoHeight] = useState(0);

    return (
      <>
        <WorkShopVideoContainer
          videoScreenWidth={videoScreenWidth}
          recordVideoHeight={setVideoHeight} />
        <div style={{
          position: 'absolute',
          transform: videoScreenWidth === '100%'
            ? 'translate(0, -' + (videoHeight / 2) + 'px)'
            : 'translate(0, -' + (videoHeight) + 'px)',
          fontSize: '32px',
          alignSelf: 'center',
        }}>
          <Squeeze>
            <h1 style={{
              fontFamily: 'RobotoSlab',
              color: 'white',
              lineHeight: '1em',
            }}>
              {heading}
            </h1>
          </Squeeze>
        </div>
      </>
    );
  }

  return (
    <>
      {useWindow().width > 800
        ? <div style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'start',
          backgroundColor: 'lightgray',
        }}>
          <TextArea />
          <div style={{
            minWidth: '50%',
          }}>
            <div style={{
            }} >
              <TitleOverVideo videoScreenWidth='50%' />
            </div>
          </div>
        </div>
        : <div style={{
          flexDirection: 'column',
          display: 'flex',
          backgroundColor: 'lightgray',
          justifyContent: 'space-evenly',
        }}>
          <TitleOverVideo videoScreenWidth='100%' />
          <TextArea />
        </div>}
    </>
  );
}

export const ContainerColumn = (props) => {
  return (<div style={{
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignContent: 'center',
    ...props.style
  }}>
    {props.children}
  </div>)
}

export const ContainerFullHeight = (props) => {
  return (<div style={{ height: "100%", ...props.style }}>
    {props.children}
  </div>)
}

export const ContainerFullWidth = (props) => {
  return (<div style={{ width: "100%", ...props.style }}>
    {props.children}
  </div>)
}

export const ContainerRow = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}
    className='container-row'
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        ...props.style
      }}>
      {props.children}
    </div>);
});

export const ContainerXpc = (props) => {
  return (<div
    style={{
      flex: props.percentage,
    }}>
    {props.child}
    {props.withGap ? <VerticalGap height="40px" /> : null}
  </div>)
}

export const DKKLinkButton = ({ children, style, to }) => {
  return (
    <LinkWithoutFormatting to={to}>
      <div style={{
        color: 'black',
        borderRadius: '24px',
        padding: '4px',
        paddingLeft: '12px',
        paddingRight: '12px',
        paddingBottom: '4px',
        cursor: 'pointer',
        border: '2px solid black',
        ...style,
      }}>
        {children}
      </div>
    </LinkWithoutFormatting>
  );
}

const NestedCards = (props) => {
  return props.percentages.map((element, index) => {
    return (
      <ContainerXpc
        key={index}
        percentage={element}
        child={props.cards['_' + index]}
        withGap={props.column === 'true'
          && index < props.percentages.length - 1} />
    );
  });
}

export const PageContainer = ({ children }) => {

  return (
    <div
      className='pageContainer'
      style={{
        opacity: 1,
        width: '100%',
        overflowX: 'hidden',
        fontFamily: 'Roboto'
      }}>
      {children}
      <YourAppDotIeFooter />
    </div>
  );
}

export const Shrink = (props) => {
  return (<div style={{
    padding: '10%',
    // width: '80%',
    // height: '80%',
    // position: 'relative',
    // top: '10%',
    // left: '10%'
  }} >
    {props.children}
  </div>);
}

export const SplitPaneVertical = (props) => {

  // smaller devices make a column instead of a row
  const isRow = useWindow().width > 1000;

  // default
  let percentages = [50, 50];
  if (props.split) {
    percentages = String(props.split).split('/');
  }

  return (isRow
    ? <ContainerRow>
      <NestedCards cards={props} percentages={percentages} />
    </ContainerRow>
    : <ContainerColumn>
      <NestedCards cards={props} percentages={percentages} column='true' />
    </ContainerColumn>);
}

export const Squeeze = (props) => {
  return (
    <div
      className='squeezing'
      style={{
        width: '80%',
        maxWidth: '80%',
        position: 'relative',
        left: '10%',
        ...props.style,
      }} >
      {props.children}
    </div>
  );
}

export const VerticalGap = (props) => {
  return (
    <div
      style={{
        height: props.height,
        width: '100%',
        clear: 'both'
      }}></div>
  );
}

export const Video = ({ src, autoplay, controlsAndSound }) => {

  const videoRef = useRef(undefined);

  useEffect(() => {
    if (!controlsAndSound) {
      videoRef.current.defaultMuted = true;
    }
    // eslint-disable-next-line
  }, [])

  const ControlsWrapper = ({ children }) => {
    return (
      controlsAndSound
        ? <video
          ref={videoRef}
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
          }}
          autoPlay={autoplay}
          loop
          playsInline
          controls >
          {children}
        </video>
        : <video
          ref={videoRef}
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
          }}
          autoPlay={autoplay}
          loop
          muted
          playsInline>
          {children}
        </video>
    );
  }

  return (
    <div className="videoContainer" style={{
      width: Math.min(useWindow().width, 785),
      height: 'auto',
    }}>
      <ControlsWrapper>
        <source
          src={src}
          type="video/mp4" />
      </ControlsWrapper>
      {/* To cover thin black line at bottom of video  - not working! */}
    </div>
  );
}

export const LinkWithoutFormatting = ({ children, to }) => {
  return (
    <Link
      to={to}
      style={{
        textDecoration: 'none',
        color: 'inherit',
      }}>
      {children}
    </Link>
  );
}
