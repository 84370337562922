import IMAGE1 from '../images/AranCucine-Volare.png'

export const post = {
  id: 'aran-volare',
  title: 'Volare from Aran Cucine',
  paragraphs: [
    'This contemporary design from the Aran Cucine range is a fabulous kitchen which we also have on display at our showroom.',
    'The design is sharp, strong and unique',
  ],
  images: [
    IMAGE1,
  ],
  products: [],
  videos: [],
}


